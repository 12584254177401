<template>
  <div>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions">
        <el-button
          type="plain"
          icon="eden-icon-upload"
          :disabled="loading || !pageData.length"
          @click="exportCustomers"
        >
          Export
        </el-button>
      </template>
    </eden-table-actions>
    <template v-if="loading">Loading...</template>
    <template v-else>
      <el-table :data="pageData" style="width: 100%">
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Name</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.company_name" class="eden-td-stack">
              <router-link
                :to="{
                  name: 'customers.corporate',
                  params: { id: scope.row.id },
                }"
              >
                <span class="font-sm text-primary">
                  {{ scope.row.company_name }}</span
                >
              </router-link>
              <span class="font-xsm capitalize">Organisation</span>
            </div>
            <div v-else class="eden-td-stack">
              <router-link
                :to="{
                  name: 'customers.individual',
                  params: { id: scope.row.id },
                }"
              >
                <span class="font-sm text-primary"> {{ scope.row.name }}</span>
              </router-link>
              <span class="font-xsm capitalize">Individual</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Email</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span v-if="scope.row.email" class="font-sm">{{
              scope.row.email
            }}</span>
            <span v-else class="font-sm">{{
              scope.row.contact_person_email_address
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone_number">
          <template slot="header">
            <span class="eden-table-header">
              Phone number
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <span
              v-if="scope.row.contact_person_phone_number"
              class="font-sm"
              >{{
                formatPhoneNumber(scope.row.contact_person_phone_number)
              }}</span
            >
            <span v-else class="font-sm">{{
              formatPhoneNumber(scope.row.phone_number)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="eden-table-header">
              Service
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.plan">
              <el-tag
                v-for="(service, index) in Object.keys(scope.row.plan)"
                :key="index"
                :type="setServiceType(service)"
                >{{ formatText(service) }}</el-tag
              >
            </div>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Created From</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm" v-if="scope.row.created_from">{{
               scope.row.created_from
            }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Date Added</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">{{
              formatDate(scope.row.created_at, "do m, y")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="allowAccessFor(['superadmin'])" width="60">
          <template slot-scope="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon--more-vertical"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="eden-icon--trash"
                  :command="{
                    action: 'delete',
                    index: scope.$index,
                    customer: scope.row,
                    type: scope.row.company_name ? 'corporate' : 'individual',
                  }"
                  >Delete customer</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <eden-pagination
        v-if="showPagination"
        :from="from"
        :to="to"
        :total="total"
        :current-page.sync="page"
      />
    </template>
    <customer-onetime-export :show.sync="exporting" :data-time="pageDataTime" />
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import onetime from "@/requests/customers/onetime";
import CustomerOnetimeExport from "@/components/Customers/Onetime/Actions/CustomerOnetimeExport.vue";

export default {
  components: { CustomerOnetimeExport },
  name: "CustomersOnetime",
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
      customer: {},
      showCustomerIndividualDelete: false,
      showCustomerCorporateDelete: false,
      exporting: false,
      pageDataTime: "",
    };
  },
  computed: {
    customers() {
      return this.$store.getters.customers_onetime;
    },
    from() {
      return this.customers.pages[this.page].from;
    },
    to() {
      return this.customers.pages[this.page].to;
    },
    total() {
      return this.customers.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      return `${this.showPagination ? total || 0 : result} One-time Customers`;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.customers.pages[this.page];
      if (pageFetched) {
        this.setPageData();
      } else {
        this.getCustomers();
      }
    },
  },
  created() {
    this.getCustomers();
  },
  methods: {
    getCustomers() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_CUSTOMERS_ONETIME, this.page)
        .then(() => {
          this.setPageData();
          this.pageDataTime = new Date();

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    search(query) {
      if (query === "") {
        this.setPageData();
        return;
      }

      this.loading = true;
      onetime
        .search(query)
        .then((response) => {
          this.pageData = response.data.data;
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.customers.pages[this.page].data;
      this.showPagination = true;
    },
    command(command) {
      const { index, customer, type } = command;
      this.customer = { index, ...customer };

      if (command.action === "delete") {
        type === "individual"
          ? (this.showCustomerIndividualDelete = true)
          : (this.showCustomerCorporateDelete = true);
      }
    },
    exportCustomers() {
      this.exporting = true;
    },
  },
};
</script>
