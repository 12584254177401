<template>
  <div>
    <el-dialog title="Export One-time Customers" :visible.sync="shouldShow" @close="closeEvent" width="30%">
      <eden-file-export :data="exportData" @exported="closeEvent" @cancelled="closeEvent">
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields" :max="5">
                  <el-checkbox v-for="(value, key, i) in fields" :key="i" :disabled="key === 'name' || key === 'email'"
                    :label="key">
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio v-for="(key, value, i) in formats" :key="i" :label="value">
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button type="primary" :loading="exporting" @click="getExportData"
            :disabled="!form.format">Export</el-button>
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
import onetime from "@/requests/customers/onetime";
export default {
  name: "CustomerOnetimeExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataTime: {
      type: [Date, String],
      default: "",
    },
  },

  data() {
    return {
      fields: {
        name: "Name",
        email: "Email",
        phone_number: "Phone Number",
        date_added: "Date Added",
        status: "Status",
        created_from: "Created from",
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["name", "email"],
        format: "",
      },
      exportData: {},
      exporting: false,
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },

  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      (this.form.fields = ["name", "email"]), (this.exporting = false);
      this.shouldShow = false;
    },

    getExportData() {
      this.exporting = true;
      onetime
        .export()
        .then((response) => {
          this.formatExportData(response.data.data);
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    formatExportData(data) {
      let updated = this.formatDateAndTime(this.dataTime, "do m, y");
      let date = this.formatDateAndTime(new Date(), "do m, y");
      let title = `One-time Customers \nDownloaded at - ${updated}\nExported at - ${date}`;
      let headers = {
        sn: "S/N",
      };
      this.form.fields.forEach((key) => (headers[key] = this.fields[key]));
      let customerData = [];

      customerData = data.map((customer, index) => {
        let export_object = {};
        Object.keys(headers).forEach((key) => {
          switch (key) {
            case "sn":
              export_object[key] = index + 1;
              break;
            case "name":
              export_object[key] = customer.company_name || customer.name;
              break;
            case "email":
              export_object[key] =
                customer.contact_person_email_address || customer.email;
              break;
            case "phone_number":
              export_object[key] = customer.phone_number
                ? this.formatPhoneNumber(customer.phone_number)
                : this.formatPhoneNumber(customer.contact_person_phone_number);
              break;
            case "status":
              export_object[key] = customer.customer_status;
              break;
            case "created_from":
              export_object[key] = customer.created_from;
              break;
            case "date_added":
              export_object[key] = this.formatDateAndTime(
                customer.created_at,
                "do m y"
              );
              break;
            default:
              break;
          }
        });
        return export_object;
      });
      this.exportData = {
        title,
        headers,
        data: customerData,
        fileName: title,
        titleMargin: 44,
        format: this.form.format,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
