<template>
  <div>
    <el-dialog title="Export Individual Customers" :visible.sync="setShow" @close="closeEvent" width="30%">
      <eden-file-export :data="exportData" @exported="closeEvent" @cancelled="closeEvent">
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields">
                  <el-checkbox v-for="(value, key, i) in fields" :key="i" :disabled="key === 'name' || key === 'email'"
                    :label="key">
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio v-for="(key, value, i) in formats" :key="i" :label="value">
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button type="primary" :loading="loading || exporting" @click="exportCustomers"
            :disabled="!form.format || !form.fields.length">Export</el-button>
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
import individual from "@/requests/customers/individual";
import {
  formatSignupMeta,
  formatCustomers,
} from "@/components/Customers/Individual/format-data";

export default {
  name: "CustomerIndividualExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataTime: {
      type: [Date, String],
      default: "",
    },
    dataToExport: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      fields: {
        name: "Name",
        email: "Email",
        status: "Status",
        gender: "Gender",
        services: "Services",
        gardener: "Gardener",
        source: "Source",
        medium: "Medium",
        content: "Content",
        home_address: "Address",
        phone_number: "Phone number",
        zone: "Zone",
        location_area: "Location area",
        date_joined: "Date joined",
        billing_date: "Billing date",
        created_from: "Created from",
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["name", "email"],
        format: "",
      },
      exportData: {},
      exporting: false,
      loading: false,
    };
  },

  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
    show() { },
  },
  mounted() { },
  methods: {
    closeEvent() {
      this.form.fields = ["name", "email"];
      this.form.format = "";
      this.setShow = false;
      this.exporting = false;
    },
    exportCustomers() {
      if (this.dataToExport.length) {
        this.formatExportData(this.dataToExport);
      } else {
        this.getExportData();
      }
    },
    getExportData() {
      this.loading = true;
      individual
        .export()
        .then((response) => {
          this.formatExportData(formatCustomers(response.data.data.customers));
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    formatExportData(data) {
      try {
        let updated = this.formatDateAndTime(this.dataTime, "do m, y");
        let date = this.formatDateAndTime(new Date(), "do m, y");
        let title = `Individual Customers \nDownloaded at - ${updated}\nExported at - ${date}`;
        let headers = {
          sn: "S/N",
        };
        let customerData = [];
        this.form.fields.forEach((key) => (headers[key] = this.fields[key]));

        customerData = data.map((customer, index) => {
          let export_object = {};
          Object.keys(headers).forEach((key) => {
            switch (key) {
              case "sn":
                export_object[key] = index + 1;
                break;
              case "name":
                export_object[key] = this.formatName(customer.name);
                break;
              case "email":
                export_object[key] = customer.email?.toLowerCase();
                break;
              case "status":
                export_object[key] = this.formatText(
                  customer.customer_status || customer.status,
                );
                break;
              case "gender":
                export_object[key] = this.formatText(customer.gender);
                break;
              case "services":
                export_object[key] = customer?.services_rendered?.join(", ");
                break;
              case "home_address":
                export_object[key] = customer.home_address;
                break;
              case "phone_number":
                export_object[key] = this.formatPhoneNumber(
                  customer.phone_number,
                );
                break;
              case "gardener":
                export_object[key] = customer.gardener;
                break;
              case "source":
                export_object[key] = formatSignupMeta(
                  customer.signup_meta,
                ).source;
                break;
              case "medium":
                export_object[key] = customer.signup_meta?.medium;
                break;
              case "content":
                export_object[key] = customer.signup_meta?.content;
                break;
              case "zone":
                export_object[key] = customer.cluster;
                break;
              case "location_area":
                export_object[key] = customer.location_area;
                break;
              case "date_joined":
                export_object[key] = this.formatDate(
                  customer.created_at,
                  "dddd do, m, y",
                );
                break;
              case "billing_date":
                export_object[key] = this.formatDate(
                  customer.billing_date,
                  "dddd do, m, y",
                );
                break;
              case "created_from":
                export_object[key] = customer.created_from;
                break;
              default:
                break;
            }
          });
          return export_object;
        });
        this.exportData = {
          title,
          headers,
          data: customerData,
          fileName: title,
          titleMargin: 44,
          format: this.form.format,
        };
        this.exporting = false;
      } catch (error) {
        console.log(error.response);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
