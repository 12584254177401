<template>
  <div class="individual-customers">
    <eden-table-actions :title="title" @search="search">
      <template slot="actions">
        <customers-individual-filter
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
        />
        <el-button
          type="plain"
          icon="eden-icon-upload"
          class="ml-10"
          :disabled="loading || !pageData?.length"
          @click="exportAction"
        >
          Export
        </el-button>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <eden-filter-items
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <el-table :data="pageData" :key="sort.property">
        <el-table-column width="200">
          <template #header>
            <eden-table-column-header
              :label="'Name'"
              :property="'name'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'name')"
            />
          </template>
          <template v-slot="scope">
            <router-link
              :to="{
                name: 'customers.individual',
                params: { id: scope.row.id },
              }"
            >
              <span class="font-sm text-primary">
                {{ formatName(scope.row.name) }}</span
              ></router-link
            >
            <span class="font-xsm capitalize">{{
              formatPersona(scope.row.persona)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template #header>
            <eden-table-column-header
              :label="'Email'"
              :property="'email'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'email')"
            />
          </template>
          <template v-slot="scope">
            <span>{{ formatDeletedEmail(scope.row.email) }}</span>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template #header>
            <eden-table-column-header
            :label="'Service'"
            :property="'service'"
            :sort-property="sort.property"
            @sort="sortPageData($event, 'service')"
          />
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.services_rendered.length">
            <el-tag
              v-for="(service, index) in (scope.row.services_rendered)"
              :key="index"
              :type="setServiceType(service)"
              >{{ formatText(service) }}</el-tag
            >
          </div>
          <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column width="140">
          <template #header>
            <eden-table-column-header
              :label="'Phone Number'"
              :property="'phone_number'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'phone_number')"
            />
          </template>
          <template v-slot="scope">
            <p class="text-faded">
              {{ formatPhoneNumber(scope.row.phone_number) }}
            </p>
          </template>
        </el-table-column>
        <!-- <el-table-column width="140">
          <template slot="header">
            <eden-table-column-header
              :label="'Wallet Balance'"
              :property="'wallet_balance'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'wallet_balance')"
            />
          </template>
          <template v-slot="scope">
            <p class="text-faded">
              {{ formatPrice(scope.row.wallet_balance) }}
            </p>
          </template>
        </el-table-column> -->
        <el-table-column width="120">
          <template #header>
            <eden-table-column-header
              :label="'Status'"
              :property="'status'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'status')"
            />
          </template>
          <template v-slot="scope">
            <span class="font-sm table--status">
              <el-tag :type="setType(scope.row.status)">
                {{ formatText(scope.row.status) }}
              </el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column width="160">
          <template #header>
            <eden-table-column-header
              :label="'Gardener'"
              :property="'gardener'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'gardener')"
            />
          </template>
          <template v-slot="scope">
            <span
              :class="['font-sm', { 'text-danger': !scope.row.gardener }]"
              >{{ scope.row.gardener || "No assigned gardener" }}</span
            >
          </template>
        </el-table-column>
        <el-table-column width="170">
          <template #header>
            <eden-table-column-header
              :label="'Zone/Location Area'"
              :property="'zone'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'zone')"
            />
          </template>
          <template v-slot="scope">
            <span class="font-sm">{{ scope.row.location_area }}</span>
            <span class="font-xsm">{{ scope.row.zone || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column width="140">
          <template #header>
            <div class="table--header">
              <span>Billing Date</span>
            </div>
          </template>
          <template v-slot="scope">
            <span v-if="scope.row.billing_date" class="font-sm">{{
              formatDate(scope.row.billing_date, "mo d, y")
            }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column width="140">
          <template #header>
            <div class="table--header">
              <span>Created from</span>
            </div>
          </template>
          <template v-slot="scope">
            <span v-if="scope.row.created_from" class="font-sm">{{
              scope.row.created_from
            }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column width="180">
          <template #header>
            <div class="table--header">
              <span>Created</span>
            </div>
          </template>
          <template v-slot="scope">
            <span class="font-sm">{{
              formatDateAndTime(scope.row.created_at, "m do, y")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="180">
          <template #header>
            <div class="table--header">
              <span>Updated</span>
            </div>
          </template>
          <template v-slot="scope">
            <span class="font-sm">{{
              formatDateAndTime(scope.row.updated_at, "m do, y")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="60">
          <template v-slot="scope">
            <customer-actions
              :customer="scope.row"
              :section="'account'"
              @fetch="getCustomers"
              @update="updateCustomer($event, scope.$index)"
            />
          </template>
        </el-table-column>
      </el-table>
      <eden-pagination
        v-if="showPagination"
        :from="from"
        :to="to"
        :total="total"
        :current-page.sync="page"
      />
    </template>
    <customer-individual-export
      :show.sync="showExportCustomers"
      :data-time="pageDataTime"
      :data-to-export="exportData"
    />
  </div>
</template>

<script>
import CustomerActions from "@/components/Customers/Individual/Actions/CustomerActions";
import CustomerIndividualExport from "@/components/Customers/Individual/Actions/CustomerIndividualExport.vue";
import * as actions from "@/store/action-types";
import individual from "@/requests/customers/individual";

import { formatCustomers } from "./format-data";
import CustomersIndividualFilter from "@/components/Customers/Individual/CustomerIndividualFilter";

export default {
  components: {
    CustomersIndividualFilter,
    CustomerActions,
    CustomerIndividualExport,
  },
  name: "CustomersIndividual",
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
      sort: {
        property: "name",
        direction: "asc",
      },
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      showExportCustomers: false,
      pageDataTime: "",
      exportData: [],
    };
  },
  computed: {
    customers() {
      return this.$store.getters.customers_individual;
    },
    zones() {
      return this.$store.getters.zones_list;
    },
    from() {
      return this.customers.pages[this.page].from;
    },
    to() {
      return this.customers.pages[this.page].to;
    },
    total() {
      return this.customers.total;
    },
    deletedAccounts() {
      return this.pageData.filter((item) => item.status === "deleted");
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      return `${
        this.showPagination ? total || 0 : result
      } Individual Customers ${
        this.deletedAccounts.length > 0
          ? `(including ${this.deletedAccounts.length} deleted account${
              this.deletedAccounts.length > 1 ? "s" : ""
            })`
          : ""
      }`;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.customers.pages[this.page];
      if (pageFetched) {
        this.setPageData();
      } else {
        this.getCustomers();
      }
    },
  },
  created() {
    this.getCustomers();
  },
  methods: {
    exportAction() {
      if (!this.showPagination) {
        this.exportData = this.pageData;
      } else {
        this.exportData = [];
      }
      this.showExportCustomers = true;
    },
    getCustomers() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_CUSTOMERS_INDIVIDUAL, this.page)
        .then(() => {
          this.setPageData();
          this.loading = false;
          this.pageDataTime = new Date();
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    search(query) {
      if (query === "") {
        this.setPageData();
        return;
      }

      this.loading = true;
      individual
        .search(query)
        .then((response) => {
          this.pageData = formatCustomers(response.data.data.customers);
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      const stringedParams = this.sortObjectAsParams(params);
      individual
        .filter(stringedParams)
        .then((response) => {
          this.filterParams = {
            status: true,
            params: params,
            paramsLabel: paramsLabel,
          };
          this.pageData = formatCustomers(response.data.data);
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.customers.pages[this.page].data;
      this.pageData = formatCustomers(this.pageData);

      const { direction, property } = this.sort;
      if (property) {
        this.sortPageData(direction, property);
      }
      this.showPagination = true;
      this.filterParams = {
        clear: true,
        status: false,
        params: {},
        paramsLabel: {},
      };
    },
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.pageData = this.sortList(this.pageData, property, direction);
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;

      if (Object.keys(keys).length) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.setPageData();
      }
    },
    clearFilters() {
      this.$router.push({ name: "customers.index" });
      this.setPageData();
    },
    updateCustomer(data, index) {
      this.pageData[index] = {
        ...this.pageData[index],
        ...data,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
