<template>
  <eden-filter
    :width="270"
    :properties.sync="properties"
    :disabled="disabled"
    :loading="loading"
    @open="getData"
    @reset="reset"
    @filter="filter"
  />
</template>

<script>
import individual from "@/requests/customers/individual";
import * as actions from "@/store/action-types";

export default {
  name: "CustomersIndividualFilter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      properties: {
        customer_status: {
          key: "statuses",
          label: "Customer Status",
          type: "list-multiple",
          options: [
            {
              label: "Subscribers",
              value: "subscriber",
            },
            {
              label: "Paused",
              value: "paused",
            },
            {
              label: "Churned",
              value: "churned",
            },
            {
              label: "Prospects",
              value: "prospect",
            },
          ],
          value: [],
          searchable: false,
        },
        account_status: {
          key: "deleted",
          label: "Account Status",
          type: "list",
          options: [
            {
              label: "Active Accounts",
              value: 0,
            },
            {
              label: "Deleted Accounts",
              value: 1,
            },
          ],
          value: [],
          searchable: false,
        },
        customer_persona: {
          key: "persona_ids",
          label: "Customer Persona",
          type: "list-multiple",
          options: [],
          value: [],
          searchable: false,
        },
        service: {
          key: "services",
          label: "Services",
          type: "list-multiple",
          options: [],
          value: [],
        },
        zones: {
          key: "zone_ids",
          label: "Zones",
          type: "list-multiple",
          options: [],
          value: [],
          searchable: true,
        },
        gardener: {
          key: "gardener_ids",
          label: "Gardener",
          type: "list-multiple",
          options: [],
          value: [],
          searchable: true,
        },
        joined: {
          key: ["joined_start_date", "joined_end_date"],
          label: "Date Joined",
          type: "daterange",
          value: [],
        },
        next_charge: {
          key: ["next_charge_start_date", "next_charge_end_date"],
          label: "Next Charge Date",
          type: "daterange",
          value: [],
        },
        menu_type: {
          key: "menu_type_variation_id",
          label: "Menu type",
          type: "list-multiple",
          options: [],
          value: [],
          searchable: false,
        },
      },
    };
  },
  computed: {
    gardeners() {
      return this.$store.getters.gardeners_list;
    },
    services() {
      return this.$store.getters.services;
    },
    zones() {
      return this.$store.getters.zones_list;
    },
    menuTypes() {
      return this.$store.getters.menu_types;
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.reset();
      }
    },
  },
  methods: {
    getData() {
      this.getPersonas();
      this.getGardeners();
      this.getZones();
      this.setServices();
      this.getMenuTypes();
    },
    getMenuTypes() {
      if (this.properties.menu_type.options.length) {
        this.loading = false;
        return;
      }

      this.menuTypes.length
        ? this.setMenuTypeOptions()
        : this.$store.dispatch(actions.GET_MENU_TYPES).then(() => {
            this.setMenuTypeOptions();
          });
    },
    getPersonas() {
      if (this.properties.customer_persona.options.length) {
        return;
      }
      individual
        .personas()
        .then((response) => {
          if (response.data.status) {
            this.properties.customer_persona.options = response.data.data.map(
              (persona) => {
                return {
                  label: this.formatPersona(persona.name),
                  value: persona.id,
                };
              },
            );
          }
        })
        .catch();
    },
    getGardeners() {
      if (this.properties.gardener.options.length) {
        this.loading = false;
        return;
      }

      this.gardeners.length
        ? this.setGardenerOptions()
        : this.$store.dispatch(actions.GET_GARDENERS_LIST).then(() => {
            this.setGardenerOptions();
          });
    },
    setMenuTypeOptions() {
      this.properties.menu_type.options = this.menuTypes.map((menuType) => {
        return {
          label: menuType.name,
          value: menuType.id,
        };
      });
      this.loading = false;
    },
    setGardenerOptions() {
      this.properties.gardener.options = this.gardeners.map((gardener) => {
        return {
          label: this.formatName(gardener.name),
          value: gardener.id,
        };
      });
      this.loading = false;
    },
    setServices() {
      this.properties.service.options = this.services.map((service) => {
        return {
          label: this.formatText(service.service_name),
          value: service.service_name.toLowerCase(),
        };
      });
    },
    getZones() {
      if (this.properties.zones.options.length) {
        this.loading = false;
        return;
      }

      this.zones.length
        ? this.setZonesOptions()
        : this.$store.dispatch(actions.GET_ZONES_LIST).then(() => {
            this.setZonesOptions();
          });
    },
    setZonesOptions() {
      this.properties.zones.options = this.zones.map((zone) => {
        return {
          label: this.formatName(zone.name),
          value: zone.id,
        };
      });
    },
    reset() {
      Object.keys(this.properties).forEach((key) => {
        this.properties[key].value = [];
      });
    },
    filter() {
      let params = {
        params: {},
        paramsLabel: {},
      };

      Object.keys(this.properties).forEach((property) => {
        const { key, type, value } = this.properties[property];
        if (value && value.length) {
          switch (type) {
            case "list":
              params.params[key] = value[0];
              params.paramsLabel[key] = this.properties[property];
              break;
            case "list-multiple":
              params.params[key] = value;
              params.paramsLabel[key] = this.properties[property];
              break;
            case "daterange":
              params.params[key[0]] = this.properties[property].value[0];
              params.params[key[1]] = this.properties[property].value[1];
              params.paramsLabel[key] = this.properties[property];
              break;
            default:
              break;
          }
        }
      });

      if (Object.keys(params.params).length) {
        this.$emit("filter", {
          params: params.params,
          paramsLabel: params.paramsLabel,
        });
      } else {
        this.$message.info("Filtering requires at least one property");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
