<template>
  <el-dialog
    title="Delete Corporate Customer"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="30%"
  >
    <el-row type="flex">
      <el-col :span="24">
        <p>
          The action cannot be undone. Are you sure you want to delete, <br />
          <span class="text-black text-bold">{{
            formatName(customerName)
          }}</span>
          ?
        </p>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button type="danger" :loading="deleting" @click="deleteCustomer"
        >Delete</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import corporate from "@/requests/customers/corporate";

export default {
  name: "CustomerCorporateDelete",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    customerId: {
      type: [String, Number],
      default: "",
    },
    customerName: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    deleteCustomer() {
      this.deleting = true;
      corporate
        .delete(this.customerId)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.deleting = false;
            this.closeEvent();
          } else {
            this.deleting = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.deleting = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
